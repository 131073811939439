

































import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { ProfileNftViewModel } from '../../viewmodels/profile-nft-viewmodel'

@Observer
@Component({
  components: {},
})
export default class extends Vue {
  @Inject({}) vm!: ProfileNftViewModel

  get itemPerRow() {
    return this.$vuetify.breakpoint.xlOnly ? 4 : this.$vuetify.breakpoint.lgAndUp ? 3 : 2
  }
}
