import { render, staticRenderFns } from "./mystery-box-tab.vue?vue&type=template&id=85ef4b76&scoped=true&"
import script from "./mystery-box-tab.vue?vue&type=script&lang=ts&"
export * from "./mystery-box-tab.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85ef4b76",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VSkeletonLoader})
